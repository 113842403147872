export function importJS(src) {
  const s = document.createElement("script");
  s.type = "text/javascript";
  s.src = src;
  document.body.appendChild(s);
}

export function getUrlKey(name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

export function importForClipboard() {
  importJS("assets/vendor/clipboard/dist/clipboard.min5f15.js");
  importJS("assets/js/components/hs.clipboard5f15.js");
  importJS("assets/js/header/js-clipboard.js");
}

export function importForPopover(){
  importJS("assets/vendor/popper.js/dist/umd/popper.min5f15.js");
  importJS("assets/vendor/bootstrap/bootstrap.min5f15.js");
  importJS("assets/js/hs.core5f15.js");
}

export function parseLongText(str,start =10,end =6) {
  if (!str) {
    return "";
  }
  var s = str;
  if (str.length > start +end) {
    s =
      str.substring(0, start) +
      "..." +
      str.substring(str.length - end, str.length);
  }
  return s;
}