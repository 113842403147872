<template>
  <main id="content" role="main">
    <input type="hidden" name="hdnAgeText" id="hdnAgeText" value="Age" />
    <input
      type="hidden"
      name="hdnDateTimeText"
      id="hdnDateTimeText"
      value="Date Time (UTC)"
    />
    <input
      type="hidden"
      name="hdnAgeTitle"
      id="hdnAgeTitle"
      value="Click to show Age Format"
    />
    <input
      type="hidden"
      name="hdnDateTimeTitle"
      id="hdnDateTimeTitle"
      value="Click to show Datetime Format"
    />
    <div class="container py-3">
      <div class="d-sm-flex align-items-center">
        <div class="mb-2 mb-sm-0">
          <h1 class="h4 mb-0">
            {{ titleName }} &nbsp;
            <span class="small text-secondary">{{ titleDes }}</span
            ><br />
          </h1>
        </div>
      </div>
    </div>
    <div class="container space-bottom-2">
      <div class="card">
        <div
          class="
            card-header
            d-flex
            justify-content-between
            align-items-center
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                href="#overview"
                data-toggle="tab"
                onclick="javascript:updatehash('');"
                >Overview</a
              >
            </li>
          </ul>
        </div>
        <div class="tab-content" v-loading="loading">
          <div
            class="tab-pane fade show active"
            id="overview"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div id="ContentPlaceHolder1_maintable" class="card-body">
              <div class="row align-items-center mt-1">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Also known as Block Number. The block height, which indicates the length of the blockchain, increases after the addition of the new block."
                  ></i
                  >Block Height:
                </div>
                <div class="col-md-9">
                  <div class="d-flex">
                    <a
                      id="preBlock"
                      class="btn btn-xs btn-icon btn-soft-info mr-1"
                      href="#"
                      @click.prevent="showBlockByNumber(-1)"
                      v-if="preBlock >= 0"
                      data-toggle="tooltip"
                      title="View previous block"
                      ><i class="fa fa-chevron-left btn-icon__inner"></i
                    ></a>
                    <span class="font-weight-sm-bold mr-2">
                      {{ currentBlock }}</span
                    >
                    <a
                      id="nextBlock"
                      class="btn btn-xs btn-icon btn-soft-info mr-1"
                      href="#"
                      @click.prevent="showBlockByNumber(1)"
                      data-toggle="tooltip"
                      title="View next block"
                      ><i class="fa fa-chevron-right btn-icon__inner"></i
                    ></a>
                  </div>
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The date and time at which a block is validated."
                  ></i
                  >Timestamp:
                </div>
                <div class="col-md-9">
                  <i class="far fa-clock small mr-1"></i>
                  {{ showTime }}
                  {{ formatDate(block.timestamp, "yyyy-MM-dd hh:mm:ss") }}
                </div>
              </div>
              <div id="ContentPlaceHolder1_div_tx_fieldname">
                <hr class="hr-space mb-2" />
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves HT value."
                    ></i
                    >Transactions:
                  </div>
                  <div
                    id="ContentPlaceHolder1_div_tx_fieldvalue"
                    class="col-md-9"
                  >
                    <a
                      class="
                        u-label u-label--value u-label--primary
                        rounded
                        my-1
                      "
                      href="#"
                      @click.prevent="showTransaction(currentBlock)"
                      data-toggle="tooltip"
                      title="Click to view Transactions"
                      >{{ block.txn }}
                      {{ getName("Common", "transactions") }}</a
                    >
                  </div>
                </div>
              </div>
              <hr class="hr-space mt-2" />

              <div id="ContentPlaceHolder1_div_tx_fieldname">
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The number of transactions in the block. Internal transaction is transactions as a result of contract execution that involves HT value."
                    ></i
                    >Token Transactions:
                  </div>
                  <div
                    id="ContentPlaceHolder1_div_tx_fieldvalue"
                    class="col-md-9"
                  >
                    <a
                      class="
                        u-label u-label--value u-label--primary
                        rounded
                        my-1
                      "
                      href="#"
                      @click.prevent="showTokenTransaction(currentBlock)"
                      data-toggle="tooltip"
                      title="Click to view Transactions"
                      >{{ block.tokentxn || 0 }}
                      {{ getName("Common", "transactions") }}</a
                    >
                  </div>
                </div>
              </div>
              <hr class="hr-space mt-2" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Validator who successfully include the block onto the blockchain."
                  ></i
                  >Validated by:
                </div>
                <div class="col-md-9">
                  <a
                    href="#"
                    v-on:click.prevent="showAddressTransaction(block.miner)"
                    >{{ block.miner }}</a
                  >
                  <!-- in 3 secs -->
                </div>
              </div>
              <hr class="hr-space" />

              <hr class="hr-space d-none" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The amount of effort required to mine a new block. The difficulty algorithm may adjust according to time."
                  ></i
                  >Difficulty:
                </div>
                <div class="col-md-9">
                  {{ block.difficulty }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Total difficulty of the chain until this block."
                  ></i
                  >Total Difficulty:
                </div>
                <div class="col-md-9">
                  {{ block.difficulty }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The block size is actually determined by the block&#39;s gas limit."
                  ></i
                  >Size:
                </div>
                <div class="col-md-9">{{ block.size }} bytes</div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The total gas used in the block and its percentage of gas filled in the block."
                  ></i
                  >Gas Used:
                </div>
                <div class="col-md-9">
                  <!-- 12,303,006 (38.06%) -->
                  {{ block.gasUsed }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Total gas limit provided by all transactions in the block."
                  ></i
                  >Gas Limit:
                </div>
                <div class="col-md-9">
                  <!-- 32,326,026 -->
                  {{ block.gasLimit }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0e
                  "
                >
                  <i
                    class="fal fa-question-circle text-secondary mr-1"
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="Any data that can be included by the miner in the block."
                  ></i
                  >Extra Data:
                </div>
                <div class="col-md-9">
                  <!-- 0xd883010005846765746888676f312e31332e34856c696e7578000000000000007174a0857120b396c190bdd39a0a145ddab1424dd69974109febb12f08ff71d23696468a3f3ed999b8503443e33060dd501a4b52ca6e53ed49067027ec300dab01 -->
                  {{ block.extraData }}
                </div>
              </div>
              <hr class="hr-space" />
              <div class="collapse" id="collapsePanel">
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The hash of the block header of the current block."
                    ></i
                    >Hash:
                  </div>
                  <div class="col-md-9">
                    <!-- 0xebdb8e2c2c3a741200c8be5c124f89b2c00f77dea577c259313353ef0e599ffc -->
                    {{ block.hash }}
                  </div>
                </div>
                <hr class="hr-space" />
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The hash of the block from which this block was generated, also known as its parent block."
                    ></i
                    >Parent Hash:
                  </div>
                  <div class="col-md-9">
                    <a
                      href="#"
                      @click.prevent="showBlockByHash(block.parentHash)"
                    >
                      <!-- 0x7fbdade6757700dfa66b17c425cd5c158d20587150bcbdd16f1fc623e2f04e69 -->
                      {{ block.parentHash }}
                    </a>
                  </div>
                </div>
                <hr class="hr-space" />
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="The mechanism which Huobi Javascript RLP encodes an empty string."
                    ></i
                    >Sha3Uncles:
                  </div>
                  <div class="col-md-9">
                    <!-- 0x1dcc4de8dec75d7aab85b567b6ccd41ad312451b948a7413f0a142fd40d49347 -->
                    {{ block.sha3Uncles }}
                  </div>
                </div>
                <hr class="hr-space" />
                <div class="row align-items-center">
                  <div
                    class="
                      col-md-3
                      font-weight-bold font-weight-sm-normal
                      mb-1 mb-md-0e
                    "
                  >
                    <i
                      class="fal fa-question-circle text-secondary mr-1"
                      data-container="body"
                      data-toggle="popover"
                      data-placement="top"
                      data-original-title=""
                      title=""
                      data-content="Block nonce is a value used during mining to demonstrate proof of work for a block."
                    ></i
                    >Nonce:
                  </div>
                  <div class="col-md-9">
                    <!-- 0x0000000000000000 -->
                    {{ block.nonce }}
                  </div>
                </div>
                <hr class="hr-space" />
              </div>
              <span id="ContentPlaceHolder1_collapsedLink_span">
                <a
                  class="d-block collapsed"
                  id="collapsedLink"
                  data-toggle="collapse"
                  href="#collapsePanel"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapsePanel"
                >
                  <span>
                    Click to see <span class="card-arrow-more">more</span>
                    <span class="card-arrow-less">less</span>
                    <span class="card-btn-arrow card-btn-arrow--up ml-2">
                      <span class="fas fa-arrow-up small"></span>
                    </span>
                  </span>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import { getUrlKey } from "../../src/assets/js/utils.js";
import { formatNumber } from "../../public/assets/js/utils.js";
import { ElMessage } from "element-plus";
import route from "../router/index";

export default {
  name: "Block",
  inject: ["reload"], //注入依赖
  data() {
    return {
      block: "",
      currentBlock: "",
      preBlock: "",
      nextBlock: "",
      showTime: "",
      menuShow: false,
      hashList: ["", "", "", ""],
      loading: false,
      interval: null,
      titleName: "",
      titleDes: "",
    };
  },
  mounted() {
    this.loading = true;
    var hash = getUrlKey("h");
    if (hash) {
      PostNet.PostWith(
        PostNetUrl.blockHashUrl,
        (info) => {
          console.log("info.data", info.data);
          this.loading = false;
          if (!info.data) {
            ElMessage.error(info.message);
            return;
          }
          this.block = info.data;
          this.currentBlock = this.block.number;
          this.preBlock = this.currentBlock - 1;
          this.nextBlock = this.currentBlock + 1;
        },
        (err) => {
          this.loading = false;
        },
        { hash: hash }
      );
      this.titleName = "Hash";
      this.titleDes = hash;
    } else {
      this.currentBlock = parseInt(getUrlKey("b"));
      PostNet.PostWith(
        PostNetUrl.blockUrl,
        (info) => {
          this.loading = false;
          if (!info.data) {
            ElMessage.error(info.message);
            return;
          }
          this.block = info.data;
          this.currentBlock = this.block.number;
          this.preBlock = this.currentBlock - 1;
          this.nextBlock = this.currentBlock + 1;
        },
        (err) => {
          this.loading = false;
        },
        { number: this.currentBlock }
      );
      this.titleName = "Block";
      this.titleDes = "#" + this.currentBlock;
    }

    this.interval = setInterval(() => {
      this.caluetTime();
    }, 1000);
  },
  beforeUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    // 一些必须的方法吧
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      if (time == 0) {
        return "";
      }
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },
    // 本页面方法
    caluetTime() {
      if (!this.block) {
        return;
      }
      try {
        if (!isNaN(this.block.timestamp)) {
          const myDate = new Date();
          const now = myDate.getTime();
          const dis = now - this.block.timestamp * 1000;
          var t = 0;
          if (dis < 0) {
            this.showTime = 0 + " " + getName("Home", "sec");
          } else if (dis < 60000) {
            t = parseInt(dis / 1000);
            this.showTime = t + " " + getName("Home", t > 1 ? "secs" : "sec");
          } else if (dis < 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "mins" : "min");
          } else if (dis < 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "hours" : "hour");
          } else if (dis < 365 * 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60 / 24);
            this.showTime = t + " " + getName("Home", t > 1 ? "days" : "day");
          } else {
            t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
            this.showTime = t + " " + getName("Home", t > 1 ? "years" : "year");
          }
          this.showTime = this.showTime + " " + getName("Home", "ago");
        }
      } catch (e) {
        console.log(e);
      }
    },

    showTransaction(blockNum) {
      // location.href = "../txs.html?b=" + blockNum;
      route.push({
        path: "/blockTxs",
        query: {
          b: blockNum,
        },
      });
    },
    showTokenTransaction(blockNum) {
      // location.href = "../tokentxs.html?b=" + blockNum;
      route.push({
        path: "/blockTokenTxs",
        query: {
          b: blockNum,
        },
      });
    },
    showBlockByHash(hash) {
      // location.href = "/block/hash.html?h=" + hash;
      route.push({
        path: "/block",
        query: {
          h: hash,
        },
      });
    },
    showAddressTransaction(address) {
      route.push({
        path: "/address",
        query: {
          a: address,
        },
      });
    },
    showBlockByNumber(index) {
      var number;
      if (index > 0) {
        number = this.nextBlock;
      } else {
        number = this.preBlock;
      }
      if (number < 0) {
        return;
      }
      route.push({
        path: "/block",
        query: {
          b: number,
        },
      });
    },
  },
};
</script>
