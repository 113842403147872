var hostUrl = ""; //http://47.243.106.253:8787
var rpcUrl = "http://8.210.29.253:8488";

var debug = process.env.NODE_ENV === "development";
if (debug) {
  // hostUrl = "http://192.168.0.155:9091";

  hostUrl = "https://explorer.rchapi.com";
}
export var PostNetUrl = {
  indexUrl: hostUrl + "/data",
  blockUrl: hostUrl + "/getBlockInfo",
  transactionList: hostUrl + "/getTransactionList",
  transactionDetail: hostUrl + "/getTransactionDetail",
  checkTransaction: hostUrl + "/checkoutTransaction",
  getTokenTransactionListByAddr: hostUrl + "/getTokenTransactionListByAddr",
  getTokenTransactionListByAddrWithCount:
    hostUrl + "/getTokenTransactionListByAddrNew",
  getTokenTransactionDetail: hostUrl + "/getTokenTransactionDetail",
  addressTransactionList: hostUrl + "/getTransactionListByAddr",
  accountList: hostUrl + "/getAccounts",
  latestInfo: hostUrl + "/getLatestInfo",
  blockHashUrl: hostUrl + "/getBlockByHash",
  addressTokenList: hostUrl + "/addressTokenList",
  tokenInfo: hostUrl + "/tokenInfo",
  tokenHolderList: hostUrl + "/tokenHolderList",
  getTokenTransactionList: hostUrl + "/getTokenTransactionList",
  getTokensTransactionList: hostUrl + "/getTokensTransactionList",
  getTokenTransactionListByBlock: hostUrl + "/getTokenTransactionListByBlock",
  tokenList: hostUrl + "/tokenList",
  getAllTransactionList: hostUrl + "/getAllTransactionList",
  getTokenHolderRank: hostUrl + "/getTokenHolderRank",

  contractList: hostUrl + "/contractList",
  compile: hostUrl + "/compile",
  compileWithFile: hostUrl + "/compileWithFile",
  solcCompileViersions: hostUrl + "/getSolcVersions",
  checkAddress: hostUrl + "/checkAddress",
  verifyContract: hostUrl + "/verifyContract",
  updateAddressInfo: hostUrl + "/createOrUpdateAddressInfo",
  updateContractInfo: hostUrl + "/createOrUpdateContractInfo",
  getContractTxns: hostUrl + "/getContractTxns",
  apiDocsUrl: hostUrl + "/getapidocurl",

  // erc20tokens
  erc20TokenInnerTrxsByAddress: hostUrl + "/erc20TokenInnerTrxsByAddress",
  erc721TokenTransferByAddress: hostUrl + "/erc721TokenTransferByAddress",
  erc20TokenInnerTrxsByHash: hostUrl + "/erc20TokenInnerTrxsByHash",
  erc721TokenIds: hostUrl + "/erc721TokenIds",

  coinInners: hostUrl + "/coinInnerTxns",
};

export var PostEthNetUrl = {
  getBalance: hostUrl + "/getBalance",
  getTokenBalance: hostUrl + "/getTokenBalance",
};

export var PostNet = {
  Post2: function (url, callback, param = {}) {
    // $.ajax({
    //     async: true, //表示请求是否异步处理
    //     type: "post", //请求类型
    //     url: url, //请求的 URL地址
    //     dataType: "json", //返回的数据类型
    //     headers: {
    //         'Content-Type': 'application/json;charset=utf8'
    //     },
    //     success: function (data) {
    //         callback(data);
    //         //在控制台打印服务器端返回的数据
    //     },
    //     data: JSON.stringify(param),
    //     error: function (data) {
    //
    //     }
    // });
    this.PostWith(url, callback, null, param);
  },

  PostWith: function (url, callback, errback, param = {}) {
    $.ajax({
      async: true, //表示请求是否异步处理
      type: "post", //请求类型
      url: url, //请求的 URL地址
      dataType: "json", //返回的数据类型
      headers: {
        "Content-Type": "application/json;charset=utf8",
      },
      success: function (data) {
        callback(data);
        //在控制台打印服务器端返回的数据
      },
      data: JSON.stringify(param),
      error: function (data) {
        if (errback != null) {
          errback(data);
        }
      },
    });
  },

  Post: function (url, callback, param = {}) {
    $.ajax({
      async: true, //表示请求是否异步处理
      type: "post", //请求类型
      url: url, //请求的 URL地址
      dataType: "json", //返回的数据类型
      success: function (data) {
        callback(data);
        //在控制台打印服务器端返回的数据
      },
      data: param,
      error: function (data) {},
    });
  },
  Get: function (url, callback, param = {}) {
    $.ajax({
      async: true, //表示请求是否异步处理
      type: "get", //请求类型
      url: url, //请求的 URL地址
      dataType: "json", //返回的数据类型
      success: function (data) {
        callback(data);
        //在控制台打印服务器端返回的数据
      },
      data: param,
      error: function (data) {},
    });
  },

  Rpc: function (method, callback, param = []) {
    $.ajax({
      async: true, //表示请求是否异步处理
      type: "post", //请求类型
      url: rpcUrl, //请求的 URL地址
      dataType: "json", //返回的数据类型
      headers: {
        "Content-Type": "application/json;charset=utf8",
      },
      success: function (data) {
        callback(data);
        //在控制台打印服务器端返回的数据
      },
      data: JSON.stringify({
        jsonrpc: "2.0",
        method: method,
        params: param,
        id: 1,
      }),
      error: function (data) {},
    });
  },
  //   s= new FormData();
  PostForm: function (url, callback, errback, param) {
    $.ajax({
      // async: true,
      // type: "post",
      // url: url,
      // processData: false,
      // contentType: false,
      // data: param,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      //   Accept: "*/*",
      // },
      async: true,
      crossDomain: true,
      url: url,
      method: "POST",
      processData: false,
      contentType: false,
      data: param,
      success: function (data) {
        callback(data);
      },
      error: function (data) {
        if (errback != null) {
          errback(data);
        }
      },
    });
  },
};
