const languageCookieName = "languageCookieName";
const language_zh = "zh-CN";
const language_en = "en";

const zh = {
  Common: {
    Unit: "RCH",
    s: "秒",
    m: "分",
    h: "时",
    d: "天",
    ago: "前",
    transactions: "条交易",
    Success: "成功",
    Failed: "失败",
    Thumbnail: "缩略图",
  },
  Home: {
    SearchTitle: "RCH生态链浏览器",
    txtSearchInput: "请输入地址/交易哈希/区块编号/通证",
    Price: "RCH 价格",
    "Latest Blocks": "最新区块",
    "Latest Transactions": "最新交易",
    "Validated By": "验证人",
    in: "在",
    ago: "前",
    From: "发送地址",
    To: "接收地址",
    "NETWORK HASHRATE": "全网算力",
    "BLOCK TIME": "区块间隔时间",
    DIFFICULTY: "全网难度",
    ACCOUNTS: "地址数",
    sec: "秒",
    secs: "秒",
    mins: "分钟",
    min: "分钟",
    hour: "小时",
    hours: "小时",
    day: "天",
    days: "天",
    year: "年",
    years: "年",
  },
  Block: {
    ago: "前",
  },
  Miners: {
    "Online Miners": "在线矿工数",
    "Online Miners Lists": "在线矿工列表",
    Login: "矿工名",
    Space: "矿工容量",
    "Last beat": "时间",
  },
  Account: {
    "Workers Online": "在线农田数",
    "Last Share": "最近发现农田时间",
    TotalSpace: "农田总空间",
    Space: "农田空间",
    WorkerList: "农田列表",
    Workers: "农田",
  },
  Nav: {
    Chia: "奇亚",
    Pool: "矿池",
    Home: "首页",
    Block: "区块",
    Miners: "矿工",
    languageCookieName: "语言",
  },
  Token: {
    "Holder Total Hold": "Holder Total Hold",
    "Total Supply": "Total Supply",
    Holders: "Holders",
    "Holder Accounts": "Holder Accounts",
    Transactions: "Transactions",
    "Token List": "Token List",
    TokensTransactionList: "TokensTransactionList",
    Contract: "Contract",
    totalSupply: "TotalSupply",
    Symbol: "Symbol",
    View: "view",
    "Txn Hash": "Txn Hash",
    Time: "Time",
    From: "From",
    To: "To",
    Value: "Value",
    Token: "Token",
    topRank: "topRank",
  },
};

const en = {
  Common: {
    Unit: "RCH",
    Thumbnail: "Thumbnail",
    Success: "Success",
    Failed: "Failed",
  },
  Home: {
    SearchTitle: "The RCH Blockchain Explorer",
    txtSearchInput: "Search by Address / Txn Hash / Block / Token",
    Price: "RCH Price",
    "Latest Blocks": "Latest block",
    "Latest Transactions": "Latest transactions",
    "Validated By": "Miner",
    in: "in",
    ago: "ago",
    From: "From",
    To: "To",
    "NETWORK HASHRATE": "HASH RATE",
    "BLOCK TIME": "Block interval time",
    DIFFICULTY: "DIFFICULTY",
    ACCOUNTS: "ACCOUNTS",
    sec: "sec",
    min: "min",
    secs: "secs",
    mins: "mins",
    hour: "hour",
    hours: "hours",
    day: "day",
    days: "days",
    year: "year",
    years: "years",
  },
  Block: {
    Reward: "Reward",
    Immature: "Immature",
    "Immature Blocks": "Immature Blocks",
    Height: "Height",
    "Miner Address": "Miner Address",
    "Time Found": "Time Found",
  },
  Miners: {
    "Online Miners": "Online Miners",
    "Online Miners Lists": "Online Miners Lists",
    Login: "Login",
    Space: "Space",
    "Last beat": "Last beat",
  },
  Account: {
    "Workers Online": "Workers Online",
    "Last Share": "Last Share",
    TotalSpace: "TotalSpace",
    Space: "Space",
    WorkerList: "WorkerList",
    Workers: "Workers",
  },
  Nav: {
    Chia: "Chia",
    Pool: "Pool",
    Home: "Home",
    Block: "Block",
    Miners: "Miners",
    languageCookieName: languageCookieName,
  },
  Token: {
    "Holder Total Hold": "Holder Total Hold",
    "Total Supply": "Total Supply",
    Holders: "Holders",
    "Holder Accounts": "Holder Accounts",
    Transactions: "Transactions",
    "Token List": "Token List",
    Contract: "Contract",
    totalSupply: "totalSupply",
    Symbol: "Symbol",
    TokensTransactionList: "TokensTransactionList",
    View: "view",
    "Txn Hash": "Txn Hash",
    Time: "Time",
    From: "From",
    To: "To",
    Value: "Value",
    Token: "Token",
    topRank: "topRank",
  },
};

function setCookie(key, value, t) {
  var oDate = new Date();
  oDate.setDate(oDate.getDate() + t);
  document.cookie =
    key + "=" + value + "; path=/; expires=" + oDate.toDateString();
}

function getCookie(key) {
  var arr1 = document.cookie.split("; "); //由于cookie是通过一个分号+空格的形式串联起来的，所以这里需要先按分号空格截断,变成[name=Jack,pwd=123456,age=22]数组类型；
  for (var i = 0; i < arr1.length; i++) {
    var arr2 = arr1[i].split("="); //通过=截断，把name=Jack截断成[name,Jack]数组；
    if (arr2[0] === key) {
      return decodeURI(arr2[1]);
    }
  }
}

export function getName(root, son) {
  let lang = "";
  lang = getCookie(languageCookieName);
  switch (lang) {
    case language_zh:
      return zh[root][son];
    case language_en:
      return en[root][son];
    default:
      // 设置英文
      setCookie(languageCookieName, language_en, 86400 * 7);
      lang = language_en;
      return en[root][son];
  }
}

// 这是语言选择弹窗变化的View
export function languageSelect() {
  getCookie(languageCookieName);
}
