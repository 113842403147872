export function formatNumber(number, decimals = 0) {
  // console.log("number is  " + number);
  var str = (number + "").split(".");
  if (str.length === 2) {
    let result = str[0] + ".";
    for (let i = 0; i < decimals; i++) {
      if (str[1].length >= i + 1) {
        result = result + str[1].substring(i, i + 1);
      } else {
        result = result + "0";
      }
    }
    return result;
  } else if (str.length === 1) {
    return str[0] + ".0000";
  } else {
    return number + "";
  }
  // return formatMoney(number, decimals, '.').replaceAll(",", "")
}

export var getDifficulty = function (hashes) {
  /*
    borrowed from https://github.com/cubedro/eth-netstats :D
  */

  var result = 0;
  var unit = "TGO";

  if (hashes !== 0 && hashes < 1000) {
    result = hashes;
    unit = "TGO";
  }

  if (hashes >= 1000 && hashes < Math.pow(1000, 2)) {
    result = hashes / 1000;
    unit = "PGO";
  }

  if (hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
    result = hashes / Math.pow(1000, 2);
    unit = "EGO";
  }

  if (hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
    result = hashes / Math.pow(1000, 3);
    unit = "BGO";
  }

  if (hashes >= Math.pow(1000, 4)) {
    result = hashes / Math.pow(1000, 4);
    unit = "KBGO";
  }
  return parseFloat(result).toFixed(2) + " " + unit + "";
};
